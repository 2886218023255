<template>
  <div class="track">
    <h1>追踪记录</h1>
    <div class="cardboxs" v-if="trackList.length">
      <div class="card-content" v-for="(item, index) in list">
        <info-card :key="index"
          @addTrackFinish="addTrackFinish"
          @delCard="delCard"
          :item="item"
        ></info-card>
      </div>
    </div>
    <div v-if="status !== 3">
      <el-button @click="addTrack" v-if="addSwitch">添加记录</el-button>
    </div>
  </div>
</template>

<script>
import InfoCard from "./InfoCard.vue";
import { reportInfo } from "@/api";
export default {
  props: {
    trackList: {
      type: Array,
    },
    report_id: [String, Number],
    status: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    trackList: {
      handler(val) {
        this.list = val;
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      addSwitch:true,
      list: [],
    };
  },
  methods: {
    async addTrackFinish() {
      const res = await reportInfo({
        report_id: this.report_id,
      });
      this.addSwitch = true;

      this.$nextTick(() => {
        this.list = res.data.data.disposes;
     });
    },
    delCard(index) {
      let curentIndex = this.trackList.findIndex((track) => track.index === index);
      this.trackList.splice(curentIndex, 1);
      this.addSwitch = true
    },
    addTrack() {
      this.addSwitch = false;
      this.trackList.push({
        created_at: "",
        content: "",
        img: [],
        report_id: this.report_id,
        isTranck: true,
        index: this.trackList.length - 1,
      });

    },
  },
  components: { InfoCard },
};
</script>

<style lang="scss" scoped>
.track {
  h1 {
    font-size: 25px;
    margin: 20px 0;
  }
  .cardboxs {
    display: flex;
    flex-direction: column;
    .card-content {
      border-bottom: 1px solid #e4e4e4;
      padding: 20px 30px;
      &:last-of-type {
        border-bottom: 0;
      }
    }
  }
  button {
    padding: 10px 30px;
    border-radius: 7px;
    background-color: red;
    color: #fff;
    margin-left: 300px;
    margin-top: 30px;
  }
}
</style>
