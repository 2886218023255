<template>
  <right-content>
    <div class="status">
      <div class="status-content">
        <el-steps :space="200" :active="status" finish-status="success">
          <el-step title="未接受"></el-step>
          <el-step title="进行中"></el-step>
          <el-step title="已处理"></el-step>
        </el-steps>
      </div>
    </div>
    <div class="content">
      <no-accept :report="reportInfo" v-if="currentStatus > 0"></no-accept>
      <process :status="reportInfo.type" :trackList="trackList" :report_id="report_id" v-if="currentStatus > 1"></process>
      <div class="bottom" v-if="currentStatus>1">
        <div class="updateRow">
          <span>发现隐患</span>
          <el-input v-model="reportInfo.hidden_danger" :disabled="currentStatus ===3"></el-input>
        </div>
        <div class="updateRow">
          <span>排查隐患</span>
          <el-input v-model="reportInfo.check_danger" :disabled="currentStatus ===3"></el-input>
        </div>
        <!-- <div class="updateRow">
          <span>发现重大隐患</span>
          <el-input v-model="reportInfo.serious_hidden_danger" :disabled="currentStatus ===3"></el-input>
        </div>
        <div class="updateRow">
          <span>排查重大隐患</span>
          <el-input v-model="reportInfo.check_serious_hidden_danger" :disabled="currentStatus ===3"></el-input>
        </div> -->
        <div class="updateRow">
          <span style="font-size:10px;">减少经济损失(单位:万元)</span>
          <el-input v-model="reportInfo.reduce_loss" :disabled="currentStatus ===3"></el-input>
        </div>
      </div>
    </div>
    <div class="statusBtn" v-if="currentStatus !== 3">
      <el-button  ref="statusBtn" @click="changeStatus">接受处理</el-button>
    </div>
  </right-content>
</template>

<script>
import RightContent from "../commonViews/RightContent.vue";
import noAccept from "./components/noAccept.vue";
import Process from "./components/process.vue";
import { reportInfo ,disposeReport,overReport} from '@/api'
export default {
  name: "list-detail",
  components: {
    Process,
    noAccept,
    RightContent,
  },
  data() {
    return {
      isDisable:false,
      currentStatus: 0,
      report_id:0,
      reportInfo: "",
      trackList: [],
      hiddenBtn:false
    };
  },
  methods:{
    async changeStatus(){
      //表示未处理
        if(this.reportInfo.type === 2) {
          const res = await disposeReport({
            report_id: this.report_id
          });
          if(res.status === 200) {
            this.$success("接受处理")
            this.reportInfo.type = 1
            this.$refs.statusBtn.$el.innerText = "完成处理";
          }
        }else if(this.reportInfo.type === 1) {
          //表示进行中,获取追踪记录traceReport
          const {hidden_danger,check_danger,reduce_loss} = this.reportInfo
          const requestData = {
            report_id:this.report_id,
            hidden_danger,
            check_danger,
            serious_hidden_danger: 0,
            check_serious_hidden_danger :0,
            reduce_loss
          }
          const res = await overReport(requestData);
          if(res.status === 200) {
            this.$success("完成处理");
            this.hiddenBtn = true
            this.$router.back();
          }
        }
    },
  },
  async created() {
    const id = this.$route.query.id;
    const name = this.$route.query.name
    if (id) {
      this.report_id = id;
      const res = await reportInfo({
        report_id:id
      });
      if(res.status === 200) {
        this.reportInfo = res.data.data;
        this.reportInfo.name = name;
        this.trackList = this.reportInfo.disposes;
      }
    }
  },
  watch:{
    status(val){
      this.currentStatus = val;
    },
  },
  computed:{
    status(){
      if(this.reportInfo.type === 2) return 1
      if(this.reportInfo.type === 1) return 2
      if(this.reportInfo.type === 3) return 3
    }
  }
};
</script>

<style lang="scss" scoped>
.status {
  display: flex;
  justify-content: center;
  align-items: center;
  .status-content {
    width: 600px;
  }
}
.bottom {
  height: 200px;
  width: 100%;
  padding: 20px 50px;
  box-sizing: border-box;
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
  .updateRow{
    display: flex;
    flex: 1;
    height: 40px;
    border-radius: 5px;
    margin-right: 50px;
    span{
      width: 200px;
      background-color: #E4E4E4;
      margin-right: 20px;
      line-height: 40px;
      text-align: center;
      border-radius: 5px;
    }
  }
}
.statusBtn{
  display: flex;
  justify-content: center;
  margin-top: 50px;
  button{
    padding: 10px 30px;
    border-radius: 7px;
    background-color: red;
    color: #fff;
  }
}

::v-deep .el-step__title {
  font-size: 12px;
  font-weight: bold;
  margin-left: -5px;
}
</style>
