<template>
  <el-upload
    action="/web/common/uploadAvatar"
    list-type="picture-card"
    :headers="{ Authorization: token }"
    :before-upload="beforeAvatarUpload"
    :on-success="handleAvatarSuccess"
    :limit="limitCount"
    :on-remove="handleRemove"
    :on-exceed="exceedFile"
    :file-list="contentImages"
  >
    <i class="el-icon-plus"></i>
  </el-upload>
</template>

<script>
export default {
  name: "MuiltUpload",
  data() {
    return {
      token: "",
      contentImages: [],
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.imageList.map((img) => {
      return {
        uid: img,
        url: img
      };
    });
  },
  watch: {
    imageList: {
      handler(val) {
        this.contentImages = val.map(img => {
          return {
            uid: img,
            url: img,
          }
        })
      },
      immediate:true,
      deep:true
    },
  },
  props: {
    imageList: {
      type: Array,
      default() {
        return [];
      },
    },
    limitCount: {
      type: Number,
      default: 8,
    },
  },
  methods: {
    exceedFile() {
      this.$message.error("上传文件超出");
    },
    handleRemove(file) {
      let index = this.imageList.indexOf(file.uid);
      this.imageList.splice(index, 1);
    },
    handleAvatarSuccess({ code, data }) {
      if (code == 200 && data.path) {
        this.imageList.push(data.path);
      }
      this.$emit("update:imageList", this.imageList);
    },
    beforeAvatarUpload(file) {
      let isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传图片格式错误!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
::v-deep .el-upload-list__item {
  width: 100px;
  height: 100px;
}
</style>
