<template>
  <div class="wrapper">
    <div class="title">
      <div>
        <svg-icon icon-class="point"></svg-icon>
        <span style="margin-left: 10px">{{moment(item.created_at).format('YYYY-MM-DD hh:mm:ss')}}</span>
      </div>
      <div v-if="item.isTranck">
        <el-button @click="delCard(item.index)" :disabled="isAddFinish">删除</el-button>
        <el-button @click="finish" :disabled="isAddFinish">完成</el-button>
      </div>
    </div>
    <div class="content-info">
      <el-input :disabled="!item.isTranck" v-model="item.content" rows="5" type="textarea"></el-input>
    </div>
    <div class="imgBox">
      <MuiltUpload v-if="item.isTranck" class="uploadImg"  :imageList.sync="item.img"></MuiltUpload>
      <div class="content-img" v-else v-for="(img,index) in item.img" :key="index">
        <el-image
          alt="图片"
          :src="img"
          :preview-src-list="[img]">
        </el-image>
      </div>
    </div>
  </div>
</template>

<script>
import  MuiltUpload from '@/components/UploadImg/MuiltUpload'
import  {traceReport} from '@/api';
import moment from 'moment'
export default {
  components:{MuiltUpload},
  data(){
    return {
      isAddFinish:false,
      moment,
    }
  },
  props:{
    item:Object
  },
  methods:{
    async finish () {
      const {img,report_id,content} = this.item;
      const res = await traceReport({img,report_id,content});
      if(res.status ===200){
        this.isAddFinish =  true
        this.$success("添加完成")
        //每次添加完成,就改状态
        this.item.isTranck = false
        this.$emit("addTrackFinish")
      }

    },
    delCard(index){
      this.$emit("delCard",index)
    }
  },
  filters:{
    filterDate(val) {
      return val.substring(0,10)
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 650px;
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  padding: 10px 20px;
  box-sizing: border-box;
  .title{
    margin-left: -15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    button{
      padding: 5px 15px;
      background-color: red;
      color: #fff;
    }
  }
  .imgBox {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    .uploadImg{
      display: flex;
    }
    .content-img {
      width: 100px;
      height: 100px;
      overflow: hidden;
      margin-right: 12px;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
::v-deep .el-textarea__inner {
  font-size: 15px;
  font-family: PingFang SC;
  color: black;
  line-height: 1.2;
}
</style>
